import axios from '@/axios.js'

export default {
    getApplications: async (params) => {
        try {
            const response = await axios.adminapi.get(`v4/apps`,{
                params: params
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
}

<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
                {{ $t('apps.campaigns.form.createButton') }}
            </button>
        </template>
        <template v-slot:datatable>
            <DataTable
                :config="datatableConfig"
                :elements="applications"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @action="handleAction"
                @filter="handleFilter"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";
import CommunityService from "@/modules/Admin/Services/CommunityService";
import ApplicationService from "@/modules/Admin/Services/ApplicationService";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";

export default {
  name: "ApplicationsPage",
  components: {DatatablePageLayout, DataTable},
  mixins: [loader],
  data() {
    return {
      applications: [],
      communities: {},
      datatableConfig: [
          { 'field' : 'id', head : this.$t('shops.id'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'name', head : this.$t('shops.name'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'status',
              head : this.$t('admin.photos.status'),
              type: 'html',
              'searchable' : true,
              searchableoptions: [
                  { value: 'production', label: 'Production' },
                  { value: 'development', label: 'Development' },
                  { value: 'onhold', label: 'On Hold' },
                  { value: 'cancelled', label: 'Cancelled' },
              ],
              options: [
                  { value: 'production', label: 'Production' },
                  { value: 'development', label: 'Development' },
                  { value: 'onhold', label: 'On Hold' },
                  { value: 'cancelled', label: 'Cancelled' },
              ],
              'sortable' : false
          },
          { 'field' : 'android_published_version', head : 'android ' + this.$t('apps.configurator.version.title'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'ios_published_version', head : 'ios ' + this.$t('apps.configurator.version.title'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'community_name', head : this.$t('admin.challenges.settings.community'), 'searchable' : false, 'sortable' : false},
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  async created() {
      const communities = await unifyPaginatedResponse(CommunityService.getCommunities)
      this.communities = Object.fromEntries(communities.map(c => [c.uuid, c.name]))
      await this.getApplications({
          filters: [{
              field: 'status',
              value: 'production;development;onhold'
          }],
          page: 1
      })
  },
  methods: {
      async getApplications(params) {
          await this.load(async () => {
              const applications = await ApplicationService.getApplications(PaginationService.parseParameters(params))
              this.applications =  this.parseApplications(applications.data)
              this.pagination = {
                  per_page: applications.per_page,
                  total: applications.total
              }
          })
      },
      async handleFilter(params) {
          if (!params.filters.find(filter => filter.field === 'status')) {
              params.filters.push({ 'field': 'status', 'value': 'production;development;onhold' })
          }

          await this.getApplications(
              {
                  filters: params.filters,
                  page: params.page
              }
          )
      },
      handleCreate() {
          this.$router.push({
              name: 'admin-application-create',
          });
      },
      async handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  await this.$router.push({
                      name: 'admin-application-edit',
                      params: {
                          'uuid': uuid,
                      }
                  });
                  break;
          }
      },
      parseApplications(applications) {
          return applications.map(application => ({
              ...application,
              community_name: this.communities[application.community_uuid],
              status: this.setStatus(application.status),
              actions: [ 'edit' ]
          }))
      },
      setStatus(status) {
          switch (status) {
              case 'onhold': return `<div class="capitalize border border-warning text-warning py-2 px-4 rounded-full">${status}</div>`
              case 'production': return `<div class="capitalize border border-success text-success py-2 px-4 rounded-full">${status}</div>`
              case 'cancelled': return `<div class="capitalize border border-danger text-danger py-2 px-4 rounded-full">${status}</div>`
              case 'development': return `<div class="capitalize border border-info text-info py-2 px-4 rounded-full">${status}</div>`
              default: return  `<div class="capitalize border border-info text-info py-2 px-4 rounded-full">${status}</div>`
          }
      },
  }
};
</script>
